<template>
    <div class="user-photo">
        <div class="user-title">
            <i class="iconfont icon-picture"></i>
            <span>{{$t('myPhoto.title')}}</span>
        </div>
        <div class="user-photo-up">
            <div>{{$t('myPhoto.updata')}}</div>
            <div>{{$t('myPhoto.text')}}</div>
            <div>
                <el-upload
                    name="imgFile"
                    class="avatar-uploader"
                    :data="cccdata"
                    :action="$isApi.uploadImg"
                    :show-file-list="false"
                    :on-success="photoSuccess">
                    <img v-if="imageUrl" :src="$isApi.http + imageUrl" class="avatar-uploader">
                    <i v-else style="border:1px dashed #d9d9d9" class="el-icon-plus avatar-uploader"></i>
                </el-upload>
            </div>
            <el-button @click="goUpdata" size="small" type="success">{{$t('myPhoto.click')}}</el-button>
        </div>
        <div class="user-title-c">{{$t('myPhoto.myPhoto')}}</div>
        <div class="user-photo-list" v-if="photoList.length > 0">
            <div v-for="(item,index) in photoList" :key="index">
                <i @click="deletePhoto(item.photoId)" class="colse-icon iconfont icon-guanbi"></i>
                <el-image 
                    fit="contain"
                    class="user-photo-item"
                    :src="item.fileUrl" 
                    :preview-src-list="[item.fileUrl]">
                </el-image>
                <div class="user-photo-time">{{$isPulic.timestampToTime(item.createDate)}}</div>
            </div>
        </div>
        <div v-else class="user-photo-none">{{$t('myPhoto.notCvailable')}}</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            cccdata: {imgType:'chatImg'},
            imageUrl: "",
            photoList: [],
            sendForm: {},
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage['sendForm'])
        this.photoLi()
    },
    methods: {
        photoLi(){
            this.$isAxios.axiosGet(this.$isApi.showAll,{userId: this.sendForm.userId},(res)=>{
                if(res.data.result){
                    this.photoList = res.data.result
                }
            })
        },
        // 上传图片
        photoSuccess(res){
            this.imageUrl = res.path
        },
        // 点击上传
        goUpdata(){
            if(this.imageUrl){
                var json = {
                    userId: this.sendForm.userId,
                    fileUrl: this.$isApi.http + this.imageUrl
                }
                this.$isAxios.axiosPost(this.$isApi.addPhoto,json,(res)=>{
                    if(res.data.result){
                        this.photoLi()
                        this.imageUrl = ""
                    }
                })
            }else{
                this.$message(this.$i18n.t('myPhoto.text_01'))
            }
        },
        // 删除照片
        deletePhoto(id){
            this.$confirm(this.$i18n.t('myPhoto.deletePhoto'), this.$i18n.t('giftComponents.reminder'), {
                confirmButtonText: this.$i18n.t('userLeft.success'),
                cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                type: 'warning'
            }).then(() => {
                this.$isAxios.axiosGet(this.$isApi.removePhoto,{photoId:id},(res)=>{
                    if(res.data.result == 'success'){
                        this.photoLi()
                        this.$message({
                            type: 'success',
                            message: this.$i18n.t('myPhoto.deleteSuccess')
                        })
                    }
                })
            }).catch(() => {})
        }
    }
}
</script>

<style scoped>
.user-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px
}
.user-title>i{
    font-size: 20px;
    display: block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    margin-right: 6px;
    color: #f00;
}
.user-photo{
    height: max-content;
    padding: 20px 30px;
    background: #fff;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .1);
}
.user-photo-up{
    height: max-content;
}
.user-photo-up>div:nth-child(1){
    font-size: 14px;
    font-weight: bold;
    color: #666;
    margin: 20px 0 10px 0
}
.user-photo-up>div:nth-child(2){
    padding: 10px 20px;
    background: rgba(255, 0, 0, .04);
    border-radius: 4px;
    font-size: 12px;
    color: #f00;
    margin-bottom: 10px;
}
.user-photo-up>div:nth-child(3){
    margin-bottom: 20px
}
.avatar-uploader{
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    object-fit: cover;
}
.user-title-c{
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #eee
}
.user-photo-list{
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.user-photo-list>div{
    width: 130px;
    height: max-content;
    margin: 0 10px 16px 0;
    position: relative;
    cursor: pointer;
}
.user-photo-list>div:hover>.colse-icon{
    display: block;
}
.user-photo-item{
    width: 130px;
    height: 130px;
    object-fit: cover;
    background: #000;
}
.colse-icon{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #fff;
    background: #ccc;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    display: block;
    position: absolute;
    right: -6px;
    top: -6px;
    z-index: 999;
    cursor: pointer;
    display: none;
}
.colse-icon:hover{
    background: #f00;
}
.user-photo-time{
    font-size: 12px;
    color: #999;
}
.user-photo-none{
    text-align: center;
    line-height: 100px;
    font-size: 18px;
    color: #999;
}
</style>